import Aura from '@primevue/themes/aura'
// @ts-ignore
import { definePreset } from '@primevue/themes'

export default definePreset(Aura, {
  primitive: {
    indigo: {
      50: '#E7F3FA',
      100: '#C0E0F3',
      200: '#99CCEC',
      300: '#72B8E5',
      400: '#54A8DE',
      500: '#318EBC',
      600: '#2C81AA',
      700: '#236C8D',
      800: '#1B5770',
      900: '#123F52',
      950: '#123F52',
    },
  },
  semantic: {
    primary: {
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}',
    },
    focusRing: {
      color: '{primary.500}',
    },
  },
})
